// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { urlSettings } from 'src/app/http-confing/settings/urlSettings';

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyCwR9FL3vORCtjr70U3AFIrbF7zN9gEOdQ',
    authDomain: 'apex-6d680.firebaseapp.com',
    projectId: 'apex-6d680',
    storageBucket: 'apex-6d680.appspot.com',
    messagingSenderId: '241884051475',
    appId: '1:241884051475:web:6bb0765c81052db8b227e6',
    measurementId: 'G-Q7NJHK414P',
  },
};

urlSettings.Settings = {
  apiHost: 'backend.apex-helpdesk.com',
  // apiHost: 'test.apex-helpdesk.com',
  apiEndPoint: 'api',
  apiProtocol: 'https',
  // apiPort: 8001,
  // apiProtocol: 'http',
  // apiHost: '192.168.1.10',
  // apiEndPoint: 'api',
  language: 'en',
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
