<div class="col-xl-12"></div>
<div class="col-xl-12 p-0"></div>

<div class="col-xl-12">
  <app-card
    cardTitle="Customer"
    blockClass="table-border-style"
    [options]="false"
  > 
    <div class="row">
      <div class="col-6" app-search (onSearch)="onSearch($event)" url="customers"></div>
      <div class="col-3 text-right my-3">
        <a target="_blank">
          <button
            type="button"
            class="btn btn-success"
            ngbTooltip="btn-primary"
            (click) = "getAllCustomersAddrInfo()"
          >
          Download Customers Info            
          </button>
        </a>
      </div>
      <div class="col-3 my-3 pr-1">
        <a href="javascript:" [routerLink]="['create']">
          <button
            type="button"
            class="btn btn-primary"
            ngbTooltip="btn-primary"
          >
          Create Customer
          </button>
        </a>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Contact_Name</th>
            <th>Address</th>
            <th>Number</th>
            <th class="text-center">Downloads</th>
            <th class="text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let customer of customers | paginate: pagination">
            <td>  {{customer.id}} </td>
            <td>{{ customer.name }}</td>
            <td>{{ customer.contact_name }}</td>
            <td>{{ customer.address }}</td>
            <td>{{ customer.number }}</td>
            <!-- -------- -->
            <td class="text-center">
              <div class="overlay-edit">
                <a target="_blank">
                  <button
                    type="button"
                    class="btn  btn-success"
                    (click)="getOneCustomerAddrInfo(customer.id)"
                  >
                    <i class="fas fa-download"></i> Info
                  </button>
                </a>
                <a target="_blank">
                  <button
                    type="button"
                    class="btn btn-success ml-2"
                    (click)="getSerialDownload(customer.id)"
                  >
                  <i class="fas fa-download"></i> serials
                  </button>
                </a>
              </div>
            </td>
            <!-- ----------- -->
            <td class="text-center">
              <div class="overlay-edit">
                <button
                  type="button"
                  class="btn btn-icon btn-warning"
                  [routerLink]="['update/' + customer.id]"
                >
                  <i class="fas fa-edit"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-icon btn-danger ml-2"
                  (click)="delete(customer.id, customer.name)"
                >
                  <i class="feather icon-trash-2"></i>
                </button>
                <button
                type="button"
                class="btn btn-icon btn-primary ml-2"
                [routerLink]="['customer_name/' + customer.name]"  
              >
                Qrs
              </button>
              
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row">
      <div class="col-12 text-center">
        <pagination-controls
          *ngIf="
            customers?.length && pagination.totalItems > pagination.itemsPerPage
          "
          (pageChange)="pageChanged($event)"
          previousLabel="Previous"
          nextLabel="Next"
          screenReaderPaginationLabel="Pagination"
          screenReaderPageLabel="page"
          screenReaderCurrentLabel="You're on page"
        >
        </pagination-controls>
      </div>
    </div>
  </app-card>
</div>
