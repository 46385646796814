import { Injectable } from '@angular/core';

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}

const NavigationItems = [
  {
    id: 'navigation',
    title: 'Navigation',
    type: 'group',
    icon: 'feather icon-monitor',
    children: [
      // {
      //   id: 'branches',
      //   title: 'Branches',
      //   type: 'item',
      //   icon: 'feather icon-home',
      //   url: '/branches'
      // },
      {
        id: 'customer',
        title: 'Customer',
        type: 'item',
        icon: 'feather icon-home',
        url: '/customer'
      },
      {
        id: 'employee',
        title: 'Employee',
        type: 'item',
        icon: 'feather icon-home',
        url: '/employee'
      },
      {
        id: 'City',
        title: 'City',
        type: 'item',
        icon: 'feather icon-home',
        url: '/cities'
      },
      {
        id: 'districts',
        title: 'district',
        type: 'item',
        icon: 'feather icon-home',
        url: '/districts'
      },
      {
        id: 'problem',
        title: 'Problem',
        type: 'item',
        icon: 'feather icon-home',
        url: '/problem'
      },
      {
        id: 'task',
        title: 'Task',
        type: 'item',
        icon: 'feather icon-home',
        url: '/task'
      },
      {
        id: 'reasons',
        title: 'Reasons',
        type: 'item',
        icon: 'feather icon-home',
        url: '/reasons'
      },
     {
        id: 'serial',
        title: 'Serial',
        type: 'item',
        icon: 'feather icon-home',
        url: '/serial'
      },
      {
        id: 'activation',
        title: 'Activation',
        type: 'item',
        icon: 'feather icon-home',
        url: '/activation'
      },
      {
        id: 'hardware',
        title: 'Hardware',
        type: 'item',
        icon: 'feather icon-home',
        url: '/hardware'
      },
      {
        id: 'hardware-brands',
        title: 'Hardware Brands',
        type: 'item',
        icon: 'feather icon-home',
        url: '/hardware-brands'
      },
    ]
  },
];

@Injectable ()
export class NavigationItem {
  public get () {
    return NavigationItems;
  }
}
