import { Component, OnInit } from '@angular/core';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { DisplayNotificationsService } from 'src/app/services/display-notifications/display-notifications.service';
import { FacadeService } from 'src/app/services/facade.service';
import { MessagingService } from 'src/app/services/notification/notification.service';

@Component({
  selector: 'app-nav-right',
  templateUrl: './nav-right.component.html',
  styleUrls: ['./nav-right.component.scss'],
  providers: [NgbDropdownConfig],
})
export class NavRightComponent implements OnInit {
  title = 'push-notification';
  message;
  notifications = [];
  readNotifications = [];
  user;
  public unreadNotifications$: Observable<number>;
  private allNotifications = [];

  constructor(
    private messagingService: MessagingService,
    private authService: AuthService,
    private displayNotifi: DisplayNotificationsService,
    private facade: FacadeService
  ) {
    // this.messagingService.currentMessage.subscribe((msg) => {
    //   this.message = msg;
    //   this.unreadNotifications$ = this.displayNotifi.unreadNotificationsSubject;
    // });
  }
  ngOnInit() {
    this.user = this.authService.User;
    // this.getNotifications();
  }

  private getNotifications() {
    this.displayNotifi.notificationsSubject.subscribe((res) => {
      if (res) {
        this.allNotifications = res;
        this.setUnreadAndRead(res);
      }
    });
  }

  private setUnreadAndRead(allNotifications) {
    this.notifications = allNotifications.filter(
      (notification) => notification.read_at === null
    );
    this.readNotifications = allNotifications.filter(
      (notification) => notification.read_at !== null
    );
  }

  markAllNotificationsAsRead() {
    this.displayNotifi.markAllNotificationsAsRead();
    if (this.notifications.length) {
      this.notifications.forEach((notification) => {
        notification.read_at = 1;
      });
      this.setUnreadAndRead(this.allNotifications);
    }
  }

  loadMoreNotifications() {
    this.displayNotifi.loadMoreNotifications();
  }

  markNotificationAsRead(notificationId: string) {
    this.displayNotifi.markNotificationAsRead(notificationId);
  }

  getNotificationDate(date: string): string {
    return (
      new Date(date).toLocaleTimeString() +
      ', ' +
      new Date(date).toLocaleDateString()
    );
  }

  public get seeMore() {
    return this.displayNotifi.seeMore;
  }

  public logout() {
    this.facade.authService.logout();
  }
}
