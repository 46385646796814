<div
  *ngIf="
    this.nextConfig.layout === 'horizontal' &&
      this.nextConfig.subLayout === 'horizontal-2';
    else mainHeader
  "
  class="container"
>
  <ng-container *ngTemplateOutlet="mainHeader"></ng-container>
</div>
<ng-template #mainHeader>
  <div class="m-header">
    <a
      (click)="navCollapse()"
      class="mobile-menu"
      href="javascript:"
      id="mobile-collapse"
    >
      <span></span>
    </a>
    <!-- <a [routerLink]="['/dashboard']" class='b-brand'>
      <img alt='' class='logo' id='main-logo' src='assets/images/logo.png'>
      <img alt='' class='logo-thumb' src='assets/images/logo-icon.png'>
    </a> -->
    <a
      (click)="toggleMobOption()"
      [ngClass]="{ on: this.menuClass }"
      class="mob-toggler"
      href="javascript:"
    >
      <i class="feather icon-more-vertical"></i>
    </a>
  </div>
  <div [style.display]="this.collapseStyle" class="collapse navbar-collapse">
    <app-nav-left class="mr-auto"></app-nav-left>
    <app-nav-right class="ml-auto"></app-nav-right>
  </div>
</ng-template>
