import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FacadeService } from 'src/app/services/facade.service';
import { IPagination } from '../customer/customer.component';

@Component({
  selector: 'app-problem',
  templateUrl: './problem.component.html',
  styleUrls: ['./problem.component.scss'],
})
export class ProblemComponent implements OnInit {
  constructor(
    private facade: FacadeService,
    private router: Router,
    private route: ActivatedRoute
  ) {}
  problems;
  pagination: IPagination = {
    currentPage: 1,
    totalItems: 0,
    itemsPerPage: 15,
  };

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((qParams) => {
      if (qParams.has('page')) {
        this.pagination.currentPage = parseInt(qParams.get('page'));
      }
      this.getAllProblems();
    });
  }

  getAllProblems() {
    this.facade.problemService
      .getAllProblems(true, this.pagination.currentPage)
      .subscribe((res) => {
        this.problems = res.data.data;
        this.pagination.itemsPerPage = res.data.per_page;
        this.pagination.totalItems = res.data.total;
      });
  }

  delete(problemId: number, problemName?: string) {
    if (window.confirm(`Are sure you want to delete ${problemName} ?`)) {
      this.facade.problemService.deleteProblem(problemId).subscribe(() => {
        const removeIndex = this.problems
          .map((problem) => problem.id)
          .indexOf(problemId);
        this.problems.splice(removeIndex, 1);
      });
    }
  }
  pageChanged(page) {
    this.pagination.currentPage = page;
    this.router.navigate([], { queryParams: { page } });
  }
}
