import { HttpClientService } from './../../http-confing/http/http-client.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BranchService {

  constructor(private http: HttpClientService) {}

  public createBranch(body) {
    return this.http.post(body, 'branch/create');
  }
  public updateBranch(body) {
    return this.http.post(body, 'branch/update');
  }
  public deleteBranch(branchId: number) {
    return this.http.post({ id: branchId }, 'branch/delete');
  }
  public getBranchData(branchId: number) {
    return this.http.get('branch/get-by-id', { id: branchId });
  }
  public getBranchByCustomer(customerID : number){
    return this.http.get('branch/get-by-customer-id' , {id: customerID})
  }
  public getBranchByCustomerPaginate( isPaginate: boolean = false, page: number = 1, id?: number){
    return this.http.get('branch/get-by-customer-id' , {    is_paginate: `${+isPaginate}`,
    page,
    id})
  }
  public getAllBranches(isPaginate: boolean = false, page: number = 1 , key?: string) {
    return this.http.get('branch/getAll', {
      is_paginate: `${+isPaginate}`,
      page,
      key
    });
  }}
