<div class="col-xl-12"></div>
<div class="col-xl-12 p-0"></div>

<div class="col-xl-12">
  <app-card
    cardTitle="Problem"
    blockClass="table-border-style"
    [options]="false"
  >
    <div class="row">
      <div class="col-12 text-right m-3 pr-5">
        <a href="javascript:" [routerLink]="['create']">
          <button
            type="button"
            class="btn btn-primary"
            ngbTooltip="btn-primary"
          >
            Create Problem
          </button>
        </a>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Name</th>
            <!-- <th>Description</th> -->
            <th class="text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let problem of problems | paginate: pagination">
            <td>{{ problem.name }}</td>
            <!-- <td>{{ problem.description }}</td> -->

            <td class="text-center">
              <div class="overlay-edit">
                <button
                  type="button"
                  class="btn btn-icon btn-success"
                  [routerLink]="['update/' + problem.id]"
                >
                  <i class="fas fa-edit"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-icon btn-danger ml-2"
                  (click)="delete(problem.id, problem.name)"
                >
                  <i class="feather icon-trash-2"></i>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row">
      <div class="col-12 text-center">
        <pagination-controls
          *ngIf="
            problems?.length && pagination.totalItems > pagination.itemsPerPage
          "
          (pageChange)="pageChanged($event)"
          previousLabel="Previous"
          nextLabel="Next"
          screenReaderPaginationLabel="Pagination"
          screenReaderPageLabel="page"
          screenReaderCurrentLabel="You're on page"
        >
        </pagination-controls>
      </div>
    </div>
  </app-card>
</div>
