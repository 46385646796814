import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { AuthComponent } from './theme/layout/auth/auth.component';
import { AuthGuardGuard } from './guards/auth-guard/auth-guard.guard';
import { NonAuthGuardGuard } from './guards/non-auth-guard/non-auth-guard.guard';
const routes: Routes = [
  {
    path: '',
    canActivate: [NonAuthGuardGuard],
    component: AdminComponent,
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
      {
        path: 'customer',
        loadChildren: () =>
          import('./apex/customer/customer.module').then(
            (module) => module.CustomerModule
          ),
      },
      {
        path: 'employee',
        loadChildren: () =>
          import('./apex/employee/employee.module').then(
            (module) => module.EmployeeModule
          ),
      },
      {
        path: 'task',
        loadChildren: () =>
          import('./apex/task/task.module').then((module) => module.TaskModule),
      },
      {
        path: 'problem',
        loadChildren: () =>
          import('./apex/problem/problem.module').then(
            (module) => module.ProblemModule
          ),
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./apex/dashboard/dashboard.module').then(
            (module) => module.DashboardModule
          ),
      },
      {
        path: 'users',
        loadChildren: () =>
          import('./apex/users/users.module').then(
            (module) => module.UsersModule
          ),
      },
      {
        path: 'profile',
        loadChildren: () =>
          import('./apex/profile/profile.module').then(
            (module) => module.ProfileModule
          ),
      },
      // {
      //   path: 'branches',
      //   loadChildren: () =>
      //     import('./apex/branches/branches.module').then(
      //       (module) => module.BranchesModule
      //     ),
      // },
      {
        path: 'reasons',
        loadChildren: () =>
          import('./apex/reasons/reasons.module').then(
            (module) => module.ReasonsModule
          ),
      },
      {
        path: 'districts',
        loadChildren: () =>
          import('./apex/district/district.module').then(
            (module) => module.DistrictModule
          ),
      },
      {
        path: 'cities',
        loadChildren: () =>
          import('./apex/city/city.module').then(
            (module) => module.CityModule
          ),
      },
      {
        path: 'serial',
        loadChildren: () =>
          import('./apex/serial/serial.module').then(
            (module) => module.SerialModule
          ),
      },
      {
        path: 'activation',
        loadChildren: () =>
          import('./apex/activation/activation.module').then(
            (module) => module.ActivationModule
          ),
      },
      {
        path: 'hardware',
        loadChildren: () =>
          import('./apex/hardware/hardware.module').then(
            (module) => module.HardwareModule
          ),
      },
      {
        path: 'hardware-brands',
        loadChildren: () =>
          import('./apex/hardware-brands/hardware-brands.module').then(
            (module) => module.HardwareBrandsModule
          ),
      },
    ],
  },
  {
    path: '',
    canActivate: [AuthGuardGuard],
    component: AuthComponent,
    children: [
      {
        path: 'auth',
        loadChildren: () =>
          import('./apex/auth/auth.module').then((module) => module.AuthModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
