import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClientService } from 'src/app/http-confing/http/http-client.service';
import { ITaskRes } from '../../Models/task.model';

@Injectable({
  providedIn: 'root'
})
export class ActivationService {

  constructor(private http: HttpClientService) {}

  public getAllQrInfo(isPaginate: boolean = false, page: number = 1 , key?: string )  { 
     return this.http.get('qr/getAll', {
      is_paginate: `${+isPaginate}`,
      page,
      key
    });
  }

  public  getAllQrDownloadInfo(){
    return this.http.get('Qr/ExportQrs');
  }


  public filterQr(Name:string , Start:Date , End:Date){
    return this.http.get(`qr/getAll?customer_name=${Name}&start_date=${Start}&end_date=${End}`);
  }

  public getFilteredQrDownload (Name:string , Start:Date , End:Date){
    return this.http.get(`customer/CustomerExportNameQrs?customer_name=${Name}&start_date=${Start}&end_date=${End}`);
  }

}
