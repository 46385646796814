import {
  FormGroup,
  FormControl,
  Validators,
  FormArray,
  AbstractControl,
} from '@angular/forms';
import { FormManage } from 'src/app/class/formVaild';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FacadeService } from 'src/app/services/facade.service';
import { IPagination } from '../customer/customer.component';
import Swal from 'sweetalert2';
import { ITasksOpts } from 'src/app/services/task/task.service';
import { ITaskRes } from 'src/app/Models/task.model';
import { IEmployeeRes } from 'src/app/Models/employee.model';

@Component({
  selector: 'app-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.scss'],
})
export class TaskComponent extends FormManage implements OnInit {
  updatetask: FormGroup;
  myData: Array<{ employee_id: number; task_id: number }> = [];
  isFilter;
  tasks: ITaskRes[] = [];
  employees: IEmployeeRes[] = [];
  clicked = false;

  filterOptions: ITasksOpts = {
    page: 1,
    is_paginate: 1,
    
  };

  pagination: IPagination = {
    currentPage: 1,
    totalItems: 0,
    itemsPerPage: 15,
  };

  assignment = [
    {
      label: 'Assigned',
      value: 2,
    },
    {
      label: 'Not Assigned',
      value: 1,
    },
  ];

  constructor(
    private facade: FacadeService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    super();
  }
  ngOnInit(): void {
    this.getQueryParams();

    this.getAllEmployees();
    this.initForm();
  }

  private getQueryParams(): void {
    this.route.queryParamMap.subscribe((qParams) => {
      const filters: ITasksOpts = {
        is_paginate: 1,
      };

      qParams.keys.forEach((key) => {
        if (key === 'is_paginate' || key === 'page') {
          filters[key] = JSON.parse(qParams.get(key));
        } else {
          filters[key] = qParams.get(key);
        }
      });

      this.filterOptions = filters;

      this.getAlltasks();
    });
  }

  initForm(): void {
    this.updatetask = new FormGroup({
      mytasks: new FormControl(''),
      tasks: new FormArray([]),
    });

    this.setForm(this.updatetask);
  }

  updateTasks(): void {
    const tasksObject = { tasks: this.myData };

    this.clicked = true;
    this.facade.taskService.getTaskNotification(tasksObject).subscribe(
      (res) => {
        // this.myData.clear();

        this.getAlltasks();

        Swal.fire(
          'Updated!',
          'Your Assigned Employee Updated Successfully',
          'success'
        );

        this.clicked = false;
      },
      (err) => {
        this.clicked = false;
        Swal.fire(
          'Cancelled',
          'No Changes Were Made Or an Error Occurred Please Try Again',
          'error'
        );

        // this.myData.clear();
      }
    );
  }

  setEmployeeId(formGroupIndex: number, taskId: number): void {
    this.AssignControls[formGroupIndex].get('task_id').setValue(taskId);

    const assignObject: { employee_id: number; task_id: number } = {
      employee_id: parseInt(
        this.AssignControls[formGroupIndex].get('employee_id').value,
        10
      ),
      task_id: this.AssignControls[formGroupIndex].get('task_id').value,
    };

    this.myData.push(assignObject);
  }

  getAllEmployees(): void {
    this.facade.employeeService.getAllEmployees().subscribe((res) => {
      this.employees = res.data;
    });
  }

  getAlltasks(): void {
    this.facade.taskService.getAllTasks(this.filterOptions).subscribe((res) => {
      this.tasks = res.data.data;

      this.fillTaskFormArray();

      this.pagination.currentPage = res.data.current_page;
      this.pagination.itemsPerPage = res.data.per_page;
      this.pagination.totalItems = res.data.total;
    });
  }

  applyFilters(): void {
    this.filterOptions.page = 1;

    this.filter();
  }

  filter(): void {
    this.router.navigate([], { queryParams: this.filterOptions });
  }

  clear(): void {
    this.filterOptions = { is_paginate: 1, page: 1 };

    this.filter();
  }
  
  pageChanged(page: number): void {
    this.filterOptions.page = page;

    this.filter();
  }

  delete(taskId: number, taskName?: string): void {
    if (window.confirm(`Are sure you want to delete ${taskName} ?`)) {
      this.facade.taskService.deleteTask(taskId).subscribe(() => {
        const removeIndex = this.tasks.map((task) => task.id).indexOf(taskId);
        this.tasks.splice(removeIndex, 1);
      });
    }
  }


  // FormArray Functions
  public get AssignControls(): AbstractControl[] {
    return (this.updatetask.get('tasks') as FormArray).controls;
  }

  public removePart(index: number): void {
    return (this.updatetask.get('tasks') as FormArray).removeAt(index);
  }

  private getTaskControl(taskId: number, employeeId: number): FormGroup {
    return new FormGroup({
      task_id: new FormControl(taskId),
      employee_id: new FormControl(employeeId),
    });
  }

  private fillTaskFormArray(): void {
    const tasksArr: AbstractControl[] = [];

    this.tasks.forEach((task) => {
      tasksArr.push(this.getTaskControl(task.id, task.employee_id));
    });

    this.updatetask.setControl('tasks', new FormArray(tasksArr));
  }
}
