import { Injectable } from '@angular/core';
import { HttpClientService } from '../../../app/http-confing/http/http-client.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';

export interface IUser {
  company: {
    id: number;
    email: string;
  };
  email: string;
  id: number;
  pages: [{ id: number; name: string; value: boolean }];
  role: {
    company_id: number;
    id: number;
    name: string;
    valid_permissions: string[];
  };
  role_id: number;
  token: string;
  username: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  // store the URL so we can redirect after logging in
  public redirectUrl: string;
  public asCompany: number;
  public expiredTokenSubject: Subject<boolean>;

  constructor(private http: HttpClientService, private router: Router) {
    this.expiredTokenSubject = new Subject();
  }

  public login(body: { email: string; password: string }) {
    return this.http.post(body, 'admin-login');
  }

  public set User(user) {
    localStorage.setItem('user', JSON.stringify(user));
  }

  public get User(): IUser {
    return JSON.parse(localStorage.getItem('user'));
  }

  public get Token() {
    if (this.User) {
      return this.User.token;
    }
  }

  public get isLoggedIn() {
    if (!this.User) {
      return false;
    }

    return true;
  }

  public get getAdmin() {
    if (this.User && this.User.role_id === 1) {
      return 'admin';
    } else {
      return 'company';
    }
  }

  public isAdmin() {
    if (this.User && this.User.role_id === 1) {
      return true;
    }

    return false;
  }

  public isCompany() {
    if (this.User && this.User.role_id !== 1) {
      if (this.User.company) {
        return this.User.company.id;
      }
    }

    return null;
  }

  public logout() {
    this.http.post({}, 'admin-logout').subscribe(
      (_) => this.removeUser(),
      (_) => this.removeUser()
    );
  }

  removeUser() {
    localStorage.removeItem('user');
    this.router.navigate(['auth/admin-login']);
  }

  public changePassword(body: {
    current_password: number;
    new_password: number;
    new_password_confirmation: number;
  }) {
    return this.http.post(body, 'change-password');
  }
}
