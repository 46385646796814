import { Injectable } from '@angular/core';
import { HttpClientService } from 'src/app/http-confing/http/http-client.service';

@Injectable({
  providedIn: 'root',
})
export class ProblemService {
  constructor(private http: HttpClientService) {}

  public createProblem(body) {
    return this.http.post(body, 'problem/create');
  }
  public updateProblem(body) {
    return this.http.post(body, 'problem/update');
  }
  public deleteProblem(problemId: number) {
    return this.http.post({ id: problemId }, 'problem/delete');
  }
  public getProblemData(problemId: number) {
    return this.http.get('problem/get', { id: problemId });
  }
  public getAllProblems(isPaginate: boolean = false, page: number = 1) {
    return this.http.get('problem/getAll', {
      is_paginate: `${+isPaginate}`,
      page,
    });
  }
}
