import { Component, OnInit } from '@angular/core';
import { FacadeService } from 'src/app/services/facade.service';
import { IPagination } from '../customer/customer.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.scss'],
})
export class EmployeeComponent implements OnInit {
  constructor(
    private facade: FacadeService,
    private router: Router,
    private route: ActivatedRoute
  ) {}
  employees;
  AllEmployeesAddrInfoLink;
  EmployeeAddrInfoLink;

  pagination: IPagination = {
    currentPage: 1,
    totalItems: 0,
    itemsPerPage: 15,
  };

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((qParams) => {
      if (qParams.has('page')) {
        this.pagination.currentPage = parseInt(qParams.get('page'));
      }
      this.getAllEmployees();
    });
  }

  getAllEmployees() {
    this.facade.employeeService
      .getAllEmployees(true, this.pagination.currentPage)
      .subscribe((res) => {
        this.employees = res.data.data;
        this.pagination.itemsPerPage = res.data.per_page;
        this.pagination.totalItems = res.data.total;
      });
  }

  getAllEmployeesAddrInfo() {
    this.facade.employeeService
      .getAllEmployeesAddrInfo()
      .subscribe((res) => {
        // console.log(this.url);        
        // console.log(res.data);  
        this.AllEmployeesAddrInfoLink = res.data;
        window.location.href = this.AllEmployeesAddrInfoLink;
      });
  }

  getOneEmployeeAddrInfo(Id: number){
    this.facade.employeeService
      .getOneEmployeeAddrInfo(Id)
      .subscribe((res) => {
        // console.log(this.url);  
        // console.log(Id);                    
        // console.log(res.data);  
        this.EmployeeAddrInfoLink = res.data     
        window.location.href = this.EmployeeAddrInfoLink;
      });
  }

  delete(employeeId: number, employeeName?: string) {
    if (window.confirm(`Are sure you want to delete ${employeeName} ?`)) {
      this.facade.employeeService.deleteEmployee(employeeId).subscribe(() => {
        const removeIndex = this.employees
          .map((employee) => employee.id)
          .indexOf(employeeId);
        this.employees.splice(removeIndex, 1);
      });
    }
  }
  pageChanged(page) {
    this.pagination.currentPage = page;
    this.router.navigate([], { queryParams: { page } });
  }
}
