import { Injectable } from '@angular/core';
import { HttpClientService } from '../../../app/http-confing/http/http-client.service';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  constructor(private http: HttpClientService) {}

  public createCustomer(body) {
    return this.http.post(body, 'customer/create');
  }
  public updateCustomer(body) {
    return this.http.post(body, 'customer/update');
  }
  public deleteCustomer(customerId: number) {
    return this.http.post({ id: customerId }, 'customer/delete');
  }
  //get qr by name
  public getQrs(C_name: string){
    return this.http.get('customer/allSerialPerId', { customer_name: C_name});
  }
  //download qr for a name
  public getFilteredQrDownload(Name:string ){
    return this.http.get('customer/CustomerExportNameQrs', {name: Name});
  }
    //download serials for a name
    public getSerialDownload(Id:number ){
      return this.http.get('customers/exportSerial', {id: Id});
    }

  public getCustomerData(customerId: number) {
    return this.http.get('customer/get', { id: customerId });
  }
  public getAllCustomer(isPaginate: boolean = false, page: number = 1 , key?: string) {
    return this.http.get('customer/getAll', {
      is_paginate: `${+isPaginate}`,
      page,
      key
    });
  }

//download all customers
  public getAllCustomersAddrInfo(){
    return this.http.get('customers/export');
  }
//download one customer
  public getOneCustomerAddrInfo(Id:number) {
    return this.http.get('customers/exportId', {id: Id});
  }

  getCityByCustomer(Id: number){
    return this.http.get('customer/get-city-by-customer' , {id: Id})
  }
  getDistrictByCityAndCustomer( customerId: number ,cityId: number ){
    return this.http.get('customer/get-all-districts' , {customer_id : customerId , city_id: cityId})
  }
  public SearchAllCustomer(isPaginate: boolean = false,  key?: string) {
    return this.http.get('customer/getAll', {
      is_paginate: `${+isPaginate}`,
      key
    });
  }
}
