import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClientService } from 'src/app/http-confing/http/http-client.service';
import { ITaskRes } from '../../Models/task.model';

@Injectable({
  providedIn: 'root',
})
export class TaskService {
  constructor(private http: HttpClientService) {}

  public createTask(body): Observable<any> {
    return this.http.post(body, 'task/create');
  }
  public updateTask(body): Observable<any> {
    return this.http.post(body, 'task/update');
  }
  public deleteTask(taskId: number): Observable<any> {
    return this.http.post({ id: taskId }, 'task/delete');
  }
  public getTaskData(taskId: number): Observable<any> {
    return this.http.get('task/get', { id: taskId });
  }

  public getAllTasks(
    tasksOpts?: ITasksOpts
  ): Observable<{
    data: {
      current_page: number;
      data: ITaskRes[];
      total: number;
      per_page: number;
    };
  }> {
    return this.http.get('task/get-All', tasksOpts);
  }

  public getTaskNotification(body): Observable<any> {
    return this.http.post(body, 'task/update-multiple-tasks');
  }
  public ChangeStatusNotification(body): Observable<any> {
    return this.http.post(body, 'task/update-paid');
  }
}

export interface ITasksOpts {
  keyCreated?: Date | string;
  transfers?: 1 | 0;
  is_paginate: 1 | 0;
  page?: number;
  key?: Date | string;
}
