import { Injectable } from '@angular/core';
import { HttpClientService } from 'src/app/http-confing/http/http-client.service';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  constructor(private http: HttpClientService) {}
  getStatistics() {
    return this.http.get('statistics');
  }
}
