<div class="btn-page">
  <app-card [customHeader]="true" [options]="false" style="border: none">
    <div class="form-material">
      <div class="row">
        <div class="col-3 mt-2 form-group">
          <h5 class="sub-title">Assignment</h5>
          <select
            name="select"
            placeholder="Select Assignment"
            class="form-control form-control-sm"
            [(ngModel)]="filterOptions.transfers"
          >
            <option *ngIf="assignment.length === 0">No Data Found</option>
            <option *ngIf="assignment.length > 0">None</option>
            <option *ngFor="let assign of assignment" [value]="assign.value">
              {{ assign.label }}
            </option>
          </select>
        </div>

        <div class="col-3">
          <h5 class="sub-title">Visited Date</h5>
          <div class="form-group">
            <div class="input-group">
              <input
                type="date"
                class="form-control"
                autocomplete="off"
                placeholder="yyyy-mm-dd"
                [(ngModel)]="filterOptions.key"
              />
              <div class="input-group-append"></div>
            </div>
          </div>
        </div>
        <div class="col-3">
          <h5 class="sub-title">Created at</h5>
          <div class="form-group">
            <div class="input-group">
              <input
                type="date"
                class="form-control"
                autocomplete="off"
                placeholder="yyyy-mm-dd"
                [(ngModel)]="filterOptions.keyCreated"
              />
              <div class="input-group-append"></div>
            </div>
          </div>
        </div>
        <div class="col-1 mt-3">
          <button
            type="submit"
            class="btn btn-primary"
            (click)="applyFilters()"
          >
            Apply
          </button>
        </div>
        <div class="col-1 mt-3">
          <button type="button" class="btn btn-danger" (click)="clear()">
            Reset
          </button>
        </div>
      </div>
    </div>
  </app-card>
</div>

<div class="col-xl-12"></div>
<div class="col-xl-12 p-0"></div>

<div class="col-xl-12">
  <app-card cardTitle="Tasks" blockClass="table-border-style" [options]="false">
    <div class="row">
      <div class="col-12 text-right m-3 pr-5">
        <a href="javascript:" [routerLink]="['create']">
          <button
            type="button"
            class="btn btn-primary"
            ngbTooltip="btn-primary"
          >
            Create Task
          </button>
        </a>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>employee</th>
            <th>customer</th>
            <th>Branch</th>
            <th>problem</th>
            <th>Cost</th>
            <th>Description</th>
            <th>Status</th>
            <th>Visited Date</th>
            <th>Payment</th>
            <th class="text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let task of tasks | paginate: pagination; let x = index">
            <td>
              <form [formGroup]="updatetask">
                <ng-container formArrayName="tasks">
                  <ng-container [formGroupName]="x">
                    <select
                      name="select"
                      class="form-control form-control-sm"
                      formControlName="employee_id"
                      style="width: auto"
                      placholder="select"
                      (change)="setEmployeeId(x, task.id)"
                    >
                      <option *ngIf="employees.length > 0" [value]="">
                        Un Assign
                      </option>
                      <option
                        *ngFor="let employee of employees"
                        [value]="employee.id"
                      >
                        {{ employee?.name }}
                      </option>
                    </select>
                  </ng-container>
                </ng-container>
              </form>
            </td>
            <td>{{ task?.customer?.name }}</td>
            <td>{{ task?.branch?.name }}</td>
            <td>{{ task?.problem?.name }}</td>
            <td>{{ task?.cost }}</td>
            <td style="white-space: pre-wrap; max-width: 300px">
              {{ task?.description }}
            </td>
            <td>{{ task?.status }}</td>
            <td>{{ task?.visited_date }}</td>
            <td *ngIf="task.paid === 1">Paid</td>
            <td *ngIf="task.paid === 0">Not Paid</td>
            <td class="text-center">
              <div class="overlay-edit">
                <button
                  type="button"
                  class="btn btn-icon btn-success"
                  [routerLink]="['update/' + task.id]"
                >
                  <i class="fas fa-edit"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-icon btn-danger ml-2"
                  (click)="delete(task.id, task.customer.name)"
                >
                  <i class="feather icon-trash-2"></i>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row">
      <div class="col-12 text-right mt-3">
        <button
          class="btn btn-primary"
          [disabled]="clicked"
          (click)="updateTasks()"
        >
          Submit
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center">
        <pagination-controls
          *ngIf="
            tasks?.length && pagination.totalItems > pagination.itemsPerPage
          "
          (pageChange)="pageChanged($event)"
          previousLabel="Previous"
          nextLabel="Next"
          screenReaderPaginationLabel="Pagination"
          screenReaderPageLabel="page"
          screenReaderCurrentLabel="You're on page"
        >
        </pagination-controls>
      </div>
    </div>
  </app-card>
</div>
