import { HardwaresService } from './hardwares/hardwares.service';
import { RegionsService } from './regions/regions.service';
import { BranchService } from './branch/branch.service';
import { ReasonsService } from './reasons/reasons.service';
import { Injectable, Injector } from '@angular/core';
import { from } from 'rxjs';
import { CustomerService } from './../services/customer/customer.service';
import { SerialService } from './serial/serial.service';
import { ActivationService } from './activation/activation.service';

import { EmployeeService } from './../services/employee/employee.service';
import { ProblemService } from './../services/problem/problem.service';
import { TaskService } from './../services/task/task.service';
import { AuthService } from './auth/auth.service';
import { HomeService } from '../apex/../services/home/home.service';
import { NotificationsService } from './notifications/notifications.service';

@Injectable({
  providedIn: 'root',
})
export class FacadeService {
  private _customerService: CustomerService;
  private _employeeService: EmployeeService;
  private _problemService: ProblemService;
  private _taskService: TaskService;
  private _authService: AuthService;
  private _homeService: HomeService;
  private _notificationService: NotificationsService;
  private _reasonService: ReasonsService;
  private _branchService: BranchService;
  private _regionService: RegionsService;
  private _serialService: SerialService;
  private _activationService : ActivationService;
  private _hardwareService : HardwaresService
  constructor(private injector: Injector) {}

  get authService(): AuthService {
    if (!this._authService) {
      this._authService = this.injector.get(AuthService);
    }

    return this._authService;
  }
  get customerService(): CustomerService {
    if (!this._customerService) {
      this._customerService = this.injector.get(CustomerService);
    }

    return this._customerService;
  }
  get employeeService(): EmployeeService {
    if (!this._employeeService) {
      this._employeeService = this.injector.get(EmployeeService);
    }

    return this._employeeService;
  }

  get problemService(): ProblemService {
    if (!this._problemService) {
      this._problemService = this.injector.get(ProblemService);
    }

    return this._problemService;
  }
  get taskService(): TaskService {
    if (!this._taskService) {
      this._taskService = this.injector.get(TaskService);
    }

    return this._taskService;
  }
  get homeService(): HomeService {
    if (!this._homeService) {
      this._homeService = this.injector.get(HomeService);
    }

    return this._homeService;
  }
  get notificationService(): NotificationsService {
    if (!this._notificationService) {
      this._notificationService = this.injector.get(NotificationsService);
    }

    return this._notificationService;
  }

  get reasonService(): ReasonsService {
    if (!this._reasonService) {
      this._reasonService = this.injector.get(ReasonsService);
    }

    return this._reasonService;
  }
  get branchService(): BranchService {
    if (!this._branchService) {
      this._branchService = this.injector.get(BranchService);
    }

    return this._branchService;
  }

  get regionSerivce(): RegionsService {
    if (!this._regionService) {
      this._regionService = this.injector.get(RegionsService);
    }

    return this._regionService;
  }

  get SerialService():SerialService {
    if (!this._serialService) {
      this._serialService = this.injector.get(SerialService);
    }

    return this._serialService;
  }

  get ActivationService():ActivationService {
    if (!this._activationService) {
      this._activationService = this.injector.get(ActivationService);
    }

    return this._activationService;
  }

  get HardwareService():HardwaresService {
    if (!this._hardwareService) {
      this._hardwareService = this.injector.get(HardwaresService);
    }
    return this._hardwareService;
  }
}
