import { Injectable } from '@angular/core';
import { HttpClientService } from '../../http-confing/http/http-client.service';

@Injectable({
  providedIn: 'root'
})
export class SerialService {

  constructor(private http: HttpClientService) {}

  public getAllSerialsInfo(isPaginate: boolean = false, page: number = 1 , key?: string) {
    return this.http.get('serial/customer-serial', {
      is_paginate: `${+isPaginate}`,
      page,
      key
    });
  }

  public getAllSerialsDownload(){
    return this.http.get('customers/exportSerials');
  }

  public getOneSerialDownload(key:string) {
    return this.http.get('customers/exportSerial', {name: key});
  }


  public searchSerial( key?: string) {
    return this.http.get('serial/customer-serial', {name:key});
  }
  
}
