import { HttpClientService } from './../../http-confing/http/http-client.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HardwaresService {

  constructor(private http: HttpClientService) {}


  public createHardware(body) {
    return this.http.post(body, 'hardware/create');
  }
  public updateHardware(body) {
    return this.http.post(body, 'hardware/update');
  }
  public getHardwareData(hwId: number) {
    return this.http.get('hardware/get', { id: hwId });
  }
  public deleteHardware(hwId: number) {
    return this.http.post({ id: hwId }, 'hardware/delete');
  }
  public getAllHardwares(isPaginate: boolean = false, page: number = 1) {
    return this.http.get('hardware/getAll', {
      is_paginate: `${+isPaginate}`,
      page,
    });
  }

  ////////////////////
  ///Hardware_Brands
  ////////////////////
  public getAllBrands(isPaginate: boolean = false, page: number = 1) {
    return this.http.get('model_name/getAll', {
      is_paginate: `${+isPaginate}`,
      page,
    });
  }
  public createBrand(body) {
    return this.http.post(body, 'model_name/create');
  }
  public deleteBrand(BrandId: number) {
    return this.http.post({ id: BrandId }, 'model_name/delete');
  }
  public getbrandData(BrandId: number) {
    return this.http.get('model_name/get', { id: BrandId });
  }
  public updateBrand(body) {
    return this.http.post(body, 'model_name/update');
  }
  public getBrandsByHardwareId(HwId: number, isPaginate: boolean = false, page: number = 1) {
    return this.http.get('hardware/get_details', { 
      Hardware_id: HwId,
      is_paginate: 
      `${+isPaginate}`,
       page, 
      });
  }
  public getHardwares() {
    return this.http.get('hardware/All-Hardware');
  }
}