import { HttpClientService } from 'src/app/http-confing/http/http-client.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ReasonsService {
  constructor(private http: HttpClientService) {}

  public createReason(body) {
    return this.http.post(body, 'reason/create');
  }
  public updateReason(body) {
    return this.http.post(body, 'reason/update');
  }
  public deleteReason(reasonId: number) {
    return this.http.post({ id: reasonId }, 'reason/delete');
  }
  public getReasonDetails(reasonId: number) {
    return this.http.get('reason/get', { id: reasonId });
  }
  public getAllReasons(isPaginate: boolean = false, page: number = 1) {
    return this.http.get('reason/getAll', {
      is_paginate: `${+isPaginate}`,
      page,
    });
  }
}
