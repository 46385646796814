import { HttpClientService } from './../../http-confing/http/http-client.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RegionsService {
  constructor(private http: HttpClientService) {}


  public createCity(body) {
    return this.http.post(body, 'city/create');
  }
  public updateCity(body) {
    return this.http.post(body, 'city/update');
  }
  public deleteCity(cityId: number) {
    return this.http.post({ id: cityId }, 'city/delete');
  }
  public getCityData(cityId: number) {
    return this.http.get('city/get', { id: cityId });
  }
  public getAllCities(isPaginate: boolean = false, page: number = 1) {
    return this.http.get('city/getAll', {
      is_paginate: `${+isPaginate}`,
      page,
    });
  }


  getDistrictsByCity(cityId){
    return this.http.get( 'city/get_details' , {city_id : cityId})
  }

  public createDistrict(body) {
    return this.http.post(body, 'district/create');
  }
  public updateDistrict(body) {
    return this.http.post(body, 'district/update');
  }
  public deleteDistrict(DistrictId: number) {
    return this.http.post({ id: DistrictId }, 'district/delete');
  }
  public getDistrictData(DistrictId: number) {
    return this.http.get('district/get', { id: DistrictId });
  }
  public getAllDistrict(isPaginate: boolean = false, page: number = 1) {
    return this.http.get('district/getAll', {
      is_paginate: `${+isPaginate}`,
      page,
    });
  }

}
