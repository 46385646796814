<div class="col-xl-12"></div>
<div class="col-xl-12 p-0"></div>

<div class="col-xl-12">
  <app-card
    cardTitle="Employee"
    blockClass="table-border-style"
    [options]="false"
  >
    <div class="row">
      <div class="col-3 pl-5 m-3">
        <a target="_blank">
          <button
            type="button"
            class="btn btn-success"
            ngbTooltip="btn-primary"
            (click) = "getAllEmployeesAddrInfo()"
          >
          Download Employees Info 
          </button>
        </a>
      </div>
      <div class="col-8 text-right m-3 pr-5">
        <a href="javascript:" [routerLink]="['create']">
          <button
            type="button"
            class="btn btn-primary"
            ngbTooltip="btn-primary"
          >
            Create Employee
          </button>
        </a>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th class="text-center">Download Info</th>
            <th class="text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let employee of employees | paginate: pagination">
            <td>{{ employee.name }}</td>
            <td>{{ employee.email }}</td>
            <td class="text-center">
              <div class="overlay-edit">
                <a target="_blank">
                <button
                  type="button"
                  class="btn btn-icon btn-success"
                  (click)="getOneEmployeeAddrInfo(employee.id)"
                >
                  <i class="fas fa-download"></i>
                </button>
                </a>
              </div>
            </td>
            <td class="text-center">
              <div class="overlay-edit">
                <button
                  type="button"
                  class="btn btn-icon btn-warning"
                  [routerLink]="['update/' + employee.id]"
                >
                  <i class="fas fa-edit"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-icon btn-danger ml-2"
                  (click)="delete(employee.id, employee.name)"
                >
                  <i class="feather icon-trash-2"></i>
                </button>
                <button
                type="button"
                class="btn btn-icon btn-primary ml-2"
                
                [routerLink]="['password/' + employee.id ]"  
              >
              <i class="fas fa-lock"></i>
                    </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row">
      <div class="col-12 text-center">
        <pagination-controls
          *ngIf="
            employees?.length && pagination.totalItems > pagination.itemsPerPage
          "
          (pageChange)="pageChanged($event)"
          previousLabel="Previous"
          nextLabel="Next"
          screenReaderPaginationLabel="Pagination"
          screenReaderPageLabel="page"
          screenReaderCurrentLabel="You're on page"
        >
        </pagination-controls>
      </div>
    </div>
  </app-card>
</div>
