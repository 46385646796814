import { Injectable } from '@angular/core';
import { HttpClientService } from 'src/app/http-confing/http/http-client.service';

@Injectable({
  providedIn: 'root',
})
export class EmployeeService {
  constructor(private http: HttpClientService) {}

  public createEmployee(body) {
    return this.http.post(body, 'employee/create');
  }
  public updateEmployee(body) {
    return this.http.post(body, 'employee/update');
  }
  public deleteEmployee(employeeId: number) {
    return this.http.post({ id: employeeId }, 'employee/delete');
  }
  public getEmployeeData(customerId: number) {
    return this.http.get('employee/get', { id: customerId });
  }
  public getAllEmployees(isPaginate: boolean = false, page: number = 1) {
    return this.http.get('employee/getAll', {
      is_paginate: `${+isPaginate}`,
      page,
    });
  }

  public getAllEmployeesAddrInfo(){
    return this.http.get('users/export');
  }

  public getOneEmployeeAddrInfo(Id:number) {    
    return this.http.get('users/export/tasks', {id: Id});
  }

  public changePassword(body){
    return this.http.post( body , 'employee/change-password')
  }
}
