<div class="form-group">
    <!-- <label for="exampleInputEmail1">{{"buttons.search" | translate}}</label> -->
    <input
     
    [formControl]="keyword"
    (input)="save()"
    
    [ngClass]="{
        'is-invalid':emptyList
      }"
      type="text"
      class="form-control"
      placeholder="search"
    />
    <!-- <app-field-error [displayError]="emptyList" [errorMsg]="'not found'  + '  ' + name "></app-field-error> -->

  
</div>