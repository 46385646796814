<ng-container *ngIf='!item.hidden'>
  <li *ngIf='item.url && !item.external' [ngClass]='item.classes' [routerLinkActive]="['active']">
    <a (click)='closeOtherMenu($event)' [routerLink]='[item.url]' [target]="item.target ? '_blank' : '_self'" class='nav-link'>
      <ng-container *ngTemplateOutlet='itemIcon'></ng-container>
      <span *ngIf='item.icon; else directTitle' class='pcoded-mtext'>{{ item.title }}</span>
      <ng-template #directTitle>
        {{item.title}}
      </ng-template>
      <ng-container *ngTemplateOutlet='itemBadge'></ng-container>
    </a>
  </li>
  <li *ngIf='item.url && item.external' [ngClass]='item.classes'>
    <a [href]='item.url' [target]="item.target ? '_blank' : '_self'">
      <ng-container *ngTemplateOutlet='itemIcon'></ng-container>
      <span *ngIf='item.icon; else directTitle' class='pcoded-mtext'>{{ item.title }}</span>
      <ng-template #directTitle>
        {{item.title}}
      </ng-template>
      <ng-container *ngTemplateOutlet='itemBadge'></ng-container>
    </a>
  </li>
  <ng-template #itemIcon>
    <span *ngIf='item.icon' class='pcoded-micon'>
      <i [ngClass]='item.icon' class='feather'></i>
    </span>
  </ng-template>
  <ng-template #itemBadge>
    <span *ngIf="item.badge && themeLayout === 'vertical'" [ngClass]='item.badge.type' class='pcoded-badge badge'>
      {{item.badge.title}}
    </span>
    <span *ngIf="item.badge && themeLayout === 'horizontal'" [ngClass]='item.badge.type' class='badge label'>
      {{item.badge.title}}
    </span>
  </ng-template>
</ng-container>
