import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FacadeService } from '../../services/facade.service';

export interface IPagination {
  itemsPerPage: number;
  currentPage: number;
  totalItems: number;
}

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss'],
})
export class CustomerComponent implements OnInit {
  pagination: IPagination = {
    currentPage: 1,
    totalItems: 0,
    itemsPerPage: 15,
  };
  constructor(
    private facade: FacadeService,
    private router: Router,
    private route: ActivatedRoute
  ) {}
  customers;
  AllCustomersAddrInfoLink;
  CustomerAddrInfoLink; 
  CustomerQrs;

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((qParams) => {
      if (qParams.has('page')) {
        this.pagination.currentPage = parseInt(qParams.get('page'));
      }
      this.getAllCustomer();
      // this.getAllCustomersAddrInfo();
    });
  }

  onSearch(e) {
    this.customers = e
    if(e==false){
      this.getAllCustomer()
    }
  }
  

  getAllCustomer() {
    this.facade.customerService
      .getAllCustomer(true, this.pagination.currentPage)
      .subscribe((res) => {
        this.pagination.itemsPerPage = res.data.per_page;
        this.pagination.totalItems = res.data.total;
        this.customers = res.data.data;
      });
  }

  getAllCustomersAddrInfo() {
    this.facade.customerService
      .getAllCustomersAddrInfo()
      .subscribe((res) => {
        // console.log(this.url);        
        // console.log(res.data);  
        this.AllCustomersAddrInfoLink = res.data;
        window.location.href = this.AllCustomersAddrInfoLink;
      });
  }

  getOneCustomerAddrInfo(Id: number){
    this.facade.customerService
      .getOneCustomerAddrInfo(Id)
      .subscribe((res) => {
        // console.log(this.url);  
        // console.log(Id);                    
        // console.log(res.data);  
        this.CustomerAddrInfoLink = res.data     
        window.location.href = this.CustomerAddrInfoLink;
      });
  }

  getSerialDownload(Id: number){
    this.facade.customerService
      .getSerialDownload(Id)
      .subscribe((res) => {
        // console.log(this.url);  
        // console.log(Id);                    
        // console.log(res.data);  
        this.CustomerQrs = res.data     
        window.location.href = this.CustomerQrs;
      });
  }

  delete(customerId: number, customerName?: string) {
    if (window.confirm(`Are sure you want to delete ${customerName} ?`)) {
      this.facade.customerService.deleteCustomer(customerId).subscribe(() => {
        const removeIndex = this.customers
          .map((customer) => customer.id)
          .indexOf(customerId);
        this.customers.splice(removeIndex, 1);
      });
    }
  }
  pageChanged(page) {
    this.pagination.currentPage = page;
    this.router.navigate([], { queryParams: { page } });
  }
}
